import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Analyze from '../views/Analyze.vue'
import Result from '../views/Result.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (from.name) {
        store.commit('setOfflineTrack', null)
        store.commit('setAccessToken', null)
        store.commit('setUserId', null)
        store.commit('setIsAuthenticated', false)
        store.commit('setUserTopGenres', false)
      }
      next()
    }
  },
  {
    path: '/analyze',
    name: 'analyze',
    component: Analyze,
    beforeEnter: (to, from, next) => {
      if (!store.state.isInitialized) next('/')
      else next()
    }
  },
  {
    path: '/access_token=:accessToken(.+)&token_type(.+)',
    name: 'accesstoken',
    beforeEnter: (to, from, next) => {
      store.commit('setAccessToken', to.params.accessToken)
      store.commit('setIsAuthenticated', true)
      store.commit('setIsInitialized')
      next('analyze')
    }
  },
  {
    path: '/result',
    name: 'result',
    component: Result,
    beforeEnter: (to, from, next) => {
      if (!store.state.isInitialized) next('/')
      else next()
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
