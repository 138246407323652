<!--eslint-disable max-len-->
<template>
  <div class="vh-100 d-flex">
    <background-image />
    <div
      class=" pb-2 pb-lg-0 flex-grow-1 d-flex flex-column position-relative"
      id="content"
    >
      <img
        src="../assets/images/logo.svg"
        id="logo-absolute"
        alt="Logo"
        class="d-none d-lg-inline position-absolute"
      >
      <div
        class="px-2 d-flex d-lg-none justify-content-center justify-content-lg-start px-2rem mt-3 mb-3 w-100 align-items-center"
        id="top"
      >
        <img
          src="../assets/images/logo.svg"
          id="logo"
          alt="Logo"
        >
      </div>
      <div class="flex-grow-1 d-none d-lg-block" />
      <div
        class="d-flex align-items-center justify-content-center flex-grow-1 w-100"
        id="video-outer-container"
      >
        <div class="position-relative text-center">
          <img
            :src="isMuted ? require('../assets/images/btn_sound_on.svg') : require('../assets/images/btn_sound_off.svg')"
            @click="onSoundChange"
            id="sound"
            class="position-absolute"
          >
          <video
            muted
            src="../assets/videos/desktop-video.mp4"
            ref="video"
            autoplay
            playsinline
            @ended="onVideoEnded"
            @canplaythrough="onVideoStarted"
          />
          <div
            class="text-center d-none d-lg-block"
            id="progress-container-mobile"
          >
            <div
              v-if="progressPercentage<100"
              class="BlockBertholdCondensedRegular mb-2 text-center px-2rem px-2"
              id="analyzing-bar-text"
            >
              {{ progressText }}
            </div>
            <progress
              v-if="progressPercentage<100"
              id="analyze-progress"
              :value="progressPercentage"
              max="100"
              class="w-75"
            />
          </div>
        </div>
      </div>
      <div
        class="text-center d-block d-lg-none"
        id="progress-container"
      >
        <div
          v-if="progressPercentage<100"
          class="BlockBertholdCondensedRegular mb-2 text-center px-2rem px-2"
          id="analyzing-bar-text"
        >
          {{ progressText }}
        </div>
        <progress
          v-if="progressPercentage<100"
          id="analyze-progress"
          :value="progressPercentage"
          max="100"
          class="w-75"
        />
      </div>
      <page-footer />
    </div>
  </div>
</template>

<script>
import PageFooter from '../components/PageFooter.vue'
import BackgroundImage from '../components/BackgroundImage.vue'

export default {
  name: 'AnalyzeView',
  components: { PageFooter, BackgroundImage },
  data() {
    return {
      videoPath: undefined,
      isMuted: true,
      progressPercentage: 0,
      progressText: 'Tu te rapproches de la résolution du mystère…',
      interval: undefined
    }
  },
  async mounted() {
    gtag('event', 'analyze_start')
    if (this.$store.state.isAuthenticated) {
      await this.$store.dispatch('loadUserPopulerGenres', 3)
      await this.$store.dispatch('loadUserInfo')
      await this.$store.dispatch('generatePlaylist')
    } else await this.$store.dispatch('loadOfflineTrackGenres')

    gtag('event', 'analyze_end')
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    onSoundChange() {
      if (this.isMuted) {
        this.$refs.video.muted = false
        gtag('event', 'click', 'video_sound_opened')
      } else {
        this.$refs.video.muted = true
        gtag('event', 'click', 'video_sound_muted')
      }
      this.isMuted = !this.isMuted
    },
    onVideoStarted() {
      this.interval = setInterval(() => {
        if (this.progressPercentage > 45 && this.progressPercentage < 90) {
          this.progressText = 'Tes habitudes d’écoute musicale sont analysées…'
        } else if (this.progressPercentage >= 90) this.progressText = 'C’est prêt !'
        if (this.progressPercentage < 100) this.progressPercentage += 1
      }, 110)
    },
    onVideoEnded() {
      this.$router.push('/result')
    }
  }
}
</script>

<style scoped>
#sound{
  cursor: pointer;
  right: 10px;
  top: 10px;
  height:15%;
  max-height: 50px;
  z-index: 9999;
}

@media (max-width: 991px) {
  #logo {
    max-width: 150px;
  }
}
@media (min-width: 992px) {
  #logo,#logo-absolute{
    width: 7vw;
    max-width: 250px;
    height: 7vw;
  }
  #logo-absolute{
    left: 25px;
    top: 25px;
    z-index: 3;
  }
  #video-outer-container{
    position: absolute;
    z-index: 2;
    min-height: 100%;
  }
  #progress-container-mobile{
    z-index:4;
    bottom: 75px;
    left: 0;
    width: 100%;
    position: absolute;
  }
  #analyzing-bar-text{
    color:white;
  }
}
video{
  width: 100%;
  max-height: 100vh;
  display: block;
}
#content{
  z-index: 2;
}

progress {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  border-radius: 10px;
}

progress[value]::-webkit-progress-bar {
  background-color: white;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: #f9b000;
  border-radius: 10px;
}

progress::-moz-progress-bar {
  background-color: white;
  border-radius: 10px;
}

progress[value]::-moz-progress-bar {
  background-color: #67c174;
}
#analyzing-bar-text{
  font-size:1.5rem;
  word-wrap: break-word;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575px) {
  #progress-container{
min-height:100px;
  }
  #analyzing-bar-text{
    min-height: 60px;
    display: flex;
    align-items: end;
    justify-content: center;
    line-height: 1.7rem;
  }
}
</style>
