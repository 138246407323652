<!--eslint-disable max-len-->
<template>
  <div>
    <background-image />
    <div
      class="container-fluid content d-flex d-lg-block flex-column flex-lg-row"
    >
      <div class="row flex-grow-1">
        <div
          id="left"
          class="col-12 col-lg-5 col-xl-4 pt-4 footer-margin text-center d-flex flex-column align-items-center"
        >
          <img
            src="../assets/images/logo.svg"
            alt="Logo"
            class="mb-4 d-block"
            id="logo"
          >
          <img
            src="../assets/images/sub-logo.png"
            alt="Burger Mystere"
            id="sub-logo"
            class="mb-4 w-100 d-block"
          >
          <h1 class="FlameBold mb-4">
            Seul ton style musical<br> peut déceler ce mystère.
          </h1>
          <p
            class="mb-4"
            id="description"
            v-if="!isOfflineTrackSelector||$isMobile()"
          >
            Découvre quel burger te correspond selon tes goûts musicaux,<br
              class="d-none d-lg-inline"
              id="description-splitter"
            > et génère ta
            playlist
            personnalisée à écouter sur Spotify.
          </p>
          <div
            class="text-center mb-4"
            v-if="!isOfflineTrackSelector"
          >
            <button
              class="btn BlockBertholdCondensedRegular btn-login btn-global"
              @click="onLogin"
            >
              JE FAIS L’EXPÉRIENCE
            </button>
          </div>

          <span
            class="d-block dont-spotify"
            v-if="!isOfflineTrackSelector"
          >Vous ne souhaitez pas connecter votre compte Spotify?</span>
          <a
            class="d-block text-decoration-underline dont-spotify text-black"
            @click.prevent="onShowOfflineTrack"
            href="#"
            v-if="!isOfflineTrackSelector"
          >Cliquez pour choisir votre piste préférée.</a>

          <h4
            v-if="isOfflineTrackSelector"
            class="mt-2"
          >
            Choisissez votre piste préférée!
          </h4>
          <vue-autosuggest
            v-if="isOfflineTrackSelector"
            :suggestions="suggestions"
            :input-props="{ id: 'autosuggest__input', placeholder: 'Nom de la piste...' }"
            @input="onSuggestionChange"
            @selected="onSuggestionSelect"
            @click="clickHandler"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
            </template>
          </vue-autosuggest>
          <div
            class="text-center mt-3"
            v-if="isOfflineTrackSelected"
          >
            <button
              class="btn BlockBertholdCondensedRegular btn-login btn-global"
              @click="onNext"
            >
              JE FAIS L’EXPÉRIENCE
            </button>
          </div>
        </div>
        <div
          id="surprise-box-mobile-wrapper"
          class="col-12 text-center mb-5 d-block d-lg-none"
        >
          <img
            src="../assets/images/pack.png"
            :srcset="require('../assets/images/pack@2x.png')+' 2x'"
            class="w-50"
          >
        </div>
        <div class="col-12 d-block d-lg-none mb-4 px-0">
          <img
            src="../assets/images/Mobile_Landing_Visual.png"
            class="w-100"
          >
        </div>
        <div
          id="right"
          class="col-12 col-lg-7 col-xl-8 d-none d-lg-block pe-lg-0"
        >
          <div class="overflow-hidden w-100 h-100 min-vh-100 position-relative">
            <img
              src="../assets/images/circle_text.png"
              class="position-absolute"
              id="circle"
            >
            <div
              class="position-absolute"
              id="circle-burger"
            >
              <img
                class="h-100"
                v-show="activeImage===1"
                src="../assets/images/home-product-1.png"
                key="1"
              >
              <img
                class="h-100"
                v-show="activeImage===2"
                src="../assets/images/home-product-2.png"
                key="2"
              >
              <img
                class="h-100"
                v-show="activeImage===3"
                src="../assets/images/home-product-3.png"
                key="3"
              >
              <img
                class="h-100"
                v-show="activeImage===4"
                src="../assets/images/home-product-4.png"
                key="4"
              >
            </div>
            <div
              id="surprise-box-wrapper"
              class="position-absolute d-flex align-items-center h-100"
            >
              <div class="position-relative">
                <img
                  src="../assets/images/surprise-box.png"
                  class="position-relative h-100"
                  id="surprise-box"
                >
                <img
                  src="../assets/images/price-icon.png"
                  class="position-absolute"
                  id="price-icon"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <page-footer is-absolute />
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import PageFooter from '../components/PageFooter.vue'
import BackgroundImage from '../components/BackgroundImage.vue'

export default {
  name: 'HomeView',
  components: {
    VueAutosuggest,
    PageFooter,
    BackgroundImage
  },
  mounted() {
    setInterval(() => {
      switch (this.activeImage) {
        case 1:
          this.activeImage = 2
          break
        case 2:
          this.activeImage = 3
          break
        case 3:
          this.activeImage = 4
          break
        case 4:
          this.activeImage = 1
          break
        default:
          this.activeImage = 1
      }
    }, 4000)
  },
  data() {
    return {
      suggestions: [],
      isOfflineTrackSelector: false,
      isOfflineTrackSelectorSearchShow: false,
      debounce: undefined,
      activeImage: 1
    }
  },
  created() {
    this.$store.dispatch('loadAppAccessToken')
    this.$store.commit('setIsInitialized')
  },
  computed: {
    isOfflineTrackSelected() {
      return this.$store.state.offlineTrack !== undefined && this.$store.state.offlineTrack !== null
    }
  },
  methods: {
    clickHandler() {
      this.$store.commit('setOfflineTrack', null)
    },
    onShowOfflineTrack() {
      this.isOfflineTrackSelector = true
      gtag('event', 'show_offline_track_selector')
    },
    onSuggestionChange(value) {
      this.$store.commit('setOfflineTrack', null)
      if (this.debounce) clearTimeout(this.debounce)

      this.debounce = setTimeout(async () => {
        if (value?.length > 2) this.suggestions = [{ data: await this.$store.dispatch('searchTracks', value) }]
        else this.suggestions = [{ data: [] }]
      }, 250)
    },
    onSuggestionSelect(value) {
      if (value.item) {
        this.$store.commit('setOfflineTrack', value.item)
        gtag('event', 'select_offline_track', value.item.name)
      } else this.$store.commit('setOfflineTrack', null)
    },
    onLogin() {
      gtag('event', 'login')
      window.location.href = `https://accounts.spotify.com/authorize?client_id=${
        process.env.VUE_APP_CLIENT_ID
      }&response_type=token&redirect_uri=${
        process.env.VUE_APP_RETURN_URL
      }&scope=${encodeURIComponent(
        'user-read-email user-top-read streaming user-read-private playlist-modify-public playlist-modify-private playlist-read-private ugc-image-upload'
      )}`
    },
    onNext() {
      gtag('event', 'go_to_analyze_without_login')
      this.$router.push('/analyze')
    }
  }
}
</script>

<style scoped>
#left {
  text-align: center;
}

#logo {
  max-width: 150px;
}
#sub-logo {
  max-width: 450px;
}

#description {
  font-size: 0.85rem;
}

.dont-spotify {
  font-size: 0.75rem;
}

@media (max-width: 991px) {
  #left{
    margin-bottom: 2rem !important;
  }
}

@media (min-width: 992px) {
  #left {
    padding-left: 2rem;
  }
  #circle {
    height: 200%;
    top: -50%;
    animation: circle-spin 300s linear infinite;
  }
  #circle-burger{
    height: 184%;
    top: -42%;
    left: -65px;
  }
  #circle-burger img{
    margin-left: 10%;
  }
  /*.fade-enter-active, .fade-leave-active {*/
  /*  transition: opacity .5s;*/
  /*}*/
  /*.fade-enter, .fade-leave-to !* .fade-leave-active below version 2.1.8 *! {*/
  /*  opacity: 0;*/
  /*}*/
  /*#surprise-box-fill{*/
  /*  z-index: 3;*/
  /*  right: -43%;*/
  /*  clip-path: ellipse(46% 35%);*/
  /*  height: 150%;*/
  /*  top: -22%;*/
  /*  background: white url("../assets/images/background.jpg");*/
  /*}*/
  #surprise-box-wrapper{
  width: 100%;
    z-index: 4;
  }

  #surprise-box{
    right: 0;
    top: 0;
    animation: surprise-box-shake 5s linear infinite;
  }
  #price-icon{
    top: -8%;
    right: -10%;
    height: 40%;
  }
  @keyframes surprise-box-shake {
    0% { transform: rotate(0deg); }
    3% { transform: rotate(10deg); }
    6% { transform: rotate(0deg); }
    9% { transform: rotate(-10deg); }
    12% { transform: rotate(0deg); }
  }
  @keyframes circle-spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

#surprise-box-wrapper{
    z-index: 4;
  }

@media (min-width:1300px ) {
  #surprise-box-wrapper>div{
    margin-left: 60%;
  }
}

@media (max-width: 1299px){
  #circle{
    height: 148%;
    top: -24%;
  }
  #circle-burger{
    height: 136%;
    top: -18%;
    left: -39px;
    left: -46px;
  }
  #surprise-box-wrapper>div{
    margin-left: 55%;
    height: 35%;
  }
}

@media (min-width:992px ) and (max-width:1199px ) {
    #circle {
      height: 124%;
      top: -13%;
    }

    #circle-burger {
      height: 112%;
      top: -7%;
      left: -24px;
    }

  #surprise-box-wrapper>div{
    margin-left: 60%;
    height: 25%;
  }
}
/*@media (min-width: 992px) and (max-width:1185px) {*/
/*  #surprise-box-wrapper{*/
/*    z-index: 4;*/
/*  }*/
/*  #surprise-box-wrapper>div{*/
/*    height: 25%;*/
/*    margin-right: 10%;*/
/*  }*/
/*}*/
/*@media (min-width: 1186px) and (max-width: 1475px) {*/
/*  #surprise-box-wrapper>div{*/
/*    height: 30%;*/
/*    margin-right: 15%;*/
/*  }*/
/*}*/
/*@media (min-width: 1476px) and (max-width: 1750px) {*/
/*  #surprise-box-wrapper>div{*/
/*    height: 38%;*/
/*    margin-right: 15%;*/
/*  }*/
/*}*/
/*@media (min-width: 1751px) {*/
/*  #surprise-box-wrapper>div{*/
/*    height: 38%;*/
/*    margin-right: 25%;*/
/*  }*/
/*}*/

/*@media (min-width:992px) and (max-width:1060px) {*/
/*  #description-splitter{*/
/*display: none !important;*/
/*  }*/
/*}*/

/*@media (min-width: 992px) and (max-aspect-ratio: 2/1)*/
/*{*/
/*#surprise-box-wrapper > div {*/
/*  height: 25%;*/
/*  margin-right: 10%;*/
/*}*/
/*}*/

/*@media (min-width: 992px) and (max-aspect-ratio: 5/3)*/
/*{*/
/*  #surprise-box-wrapper > div {*/
/*    height: 20%;*/
/*    margin-right: 10%;*/
/*  }*/
/*}*/
/*@media (min-width: 992px) and (max-aspect-ratio: 55/48)*/
/*{*/
/*  #surprise-box-wrapper > div {*/
/*    height: 15%;*/
/*    margin-right: 10%;*/
/*  }*/
/*}*/
/*@media (min-width: 992px) and (max-aspect-ratio: 1/1)*/
/*{*/
/*  #surprise-box-wrapper > div {*/
/*    height: 15%;*/
/*    margin-right: 5%;*/
/*  }*/
/*}*/
</style>

<style>
@media (max-width: 991px) {
  #autosuggest {
    width: 85%;
  }
}
#autosuggest__input {
  outline: none;
  position: relative;
  display: block;
  border:none;
  border-bottom: 2px solid #bc1e31;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  background: none;
  font-size: 1.2rem;
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: relative;
  z-index: 10000001;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0px;
  max-height: 400px;
  overflow: hidden;
  font-size: 1rem;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 5px 15px;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

#autosuggest ul:nth-child(1)>.autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results-before {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results .autosuggest__results-item.autosuggest__results-item--highlighted {
  color: #bc1e31;
}
.autosuggest__results{
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
@media(min-width: 992px){
  #autosuggest{
    width: 85%;
  }
}
</style>
