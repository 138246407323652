/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import Common from '../common'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    accessToken: undefined,
    userId: undefined,
    isAuthenticated: false,
    staticGenreGroups: [],
    otherStaticGenreGroup: undefined,
    userTopGenres: [],
    offlineTrack: undefined,
    createdPlayListId: undefined,
    isInitialized: false,
    totalTrackDuration:0
  },
  getters: {
    getCreatedPlayListId(state) {
      if (state.isAuthenticated) return state.createdPlayListId
      return state.userTopGenres[0].playList.id
    }
  },
  mutations: {
    setAccessToken(state, payload) {
      state.accessToken = payload
    },
    setUserId(state, payload) {
      state.userId = payload
    },
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload
    },
    setStaticGenreGroups(state, payload) {
      state.staticGenreGroups = payload
    },
    setUserTopGenres(state, payload) {
      state.userTopGenres = payload
    },
    setOfflineTrack(state, payload) {
      state.offlineTrack = payload
    },
    setOtherStaticGenreGroup(state, payload) {
      state.otherStaticGenreGroup = payload
    },
    setCreatedPlayListId(state, payload) {
      state.createdPlayListId = payload
    },
    setIsInitialized(state) {
      state.isInitialized = true
    },
    setTotalTrackDuration(state,payload){
      state.totalTrackDuration=payload
    }
  },
  actions: {
    async loadStaticInfos(context) {
      const response = await fetch(`${process.env.BASE_URL}keywords.json`)
      if (response.ok) {
        const responseJson = await response.json()
        context.commit('setStaticGenreGroups', responseJson.list)
        context.commit('setOtherStaticGenreGroup', responseJson.other)
      }
    },
    async loadAppAccessToken(context) {
      const response = await fetch('https://accounts.spotify.com/api/token', {
        method: 'POST',
        headers: {
          Authorization: `Basic ${window.btoa(
            `${process.env.VUE_APP_CLIENT_ID}:${process.env.VUE_APP_CLIENT_SECRET}`
          )}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'grant_type=client_credentials'
      })

      const responseJson = await response.json()
      context.commit('setAccessToken', responseJson.access_token)
    },
    async loadUserInfo(context) {
      const response = await fetch('https://api.spotify.com/v1/me', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${context.state.accessToken}`
        }
      })

      const responseJson = await response.json()
      context.commit('setUserId', responseJson.id)
    },
    async searchTracks(context, text) {
      const response = await fetch(
        `https://api.spotify.com/v1/search?q=${encodeURIComponent(text)}&limit=3&market=${
          process.env.VUE_APP_MARKET
        }&type=track`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${context.state.accessToken}`
          }
        }
      )
      const responseJson = await response.json()
      const songList = responseJson.tracks.items.map((item) => ({
        name: `${item.artists[0].name} - ${item.name}`,
        trackId: item.id,
        artistIds: item.artists.map((artist) => artist.id)
      }))
      return songList
    },
    async loadUserPopulerGenres(context, compareCount) {
      let isUserTopGenresLoaded = false
      const topTracks = await fetch('https://api.spotify.com/v1/me/top/tracks?limit=50&time_range=long_term', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${context.state.accessToken}`
        }
      })
      const topTracksJson = await topTracks.json()
      if (topTracksJson?.items?.length > 0) {
        const duration= topTracksJson.items.reduce((oldValue,newValue)=>{
          oldValue+=newValue.duration_ms;
          return oldValue;
        },0)
        context.commit('setTotalTrackDuration',duration)
        const artistIds = new Set(
          topTracksJson.items.flatMap((track) => track.artists?.map((artist) => artist.id))
        )
        if (artistIds?.size > 0) {
          const artists = await fetch(
            `https://api.spotify.com/v1/artists?ids=${encodeURIComponent(
              [...artistIds].slice(0, 50).join(',')
            )}`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${context.state.accessToken}`
              }
            }
          )
          const artistsJson = await artists.json()
          if (artistsJson?.artists?.length > 0) {
            let topGenres = artistsJson.artists.flatMap((artist) => artist.genres)
            topGenres = topGenres.reduce((total, current) => {
              const matchedItem = total.find((item) => item.name === current)
              if (matchedItem) matchedItem.count += 1
              else total.push({ name: current, count: 1 })
              return total
            }, [])
            topGenres.sort((a, b) => a.count - b.count)
            topGenres.reverse()

            const numberedGenres=topGenres.filter((item,index)=>index<compareCount)
            const numberedTotalGenresCount=numberedGenres.reduce((oldGenre,newGenre)=>{
              oldGenre+=newGenre.count
              return oldGenre
            },0)
            let finalList=[]
            numberedGenres.forEach((item) => {
              let finalItem={}
              const percentage = Math.round((item.count / numberedTotalGenresCount) * 100)
              const matchedGenreGroup=context.state.staticGenreGroups.find((staticItem) => staticItem.keywords.includes(item.name))
              if (matchedGenreGroup)
                finalItem={ ...JSON.parse(JSON.stringify(matchedGenreGroup)), title: item.name,percentage:percentage,count:item.count}
              else
                finalItem = { ...JSON.parse(JSON.stringify(context.state.otherStaticGenreGroup)), title: item.name,percentage:percentage,count:item.count}

              const { product,playList } = finalItem
              if (product.name.length > 1) {
                const random = Common.randomInteger(0, product.name.length - 1)
                product.name = product.name[random]
                product.imageUrl = product.imageUrl[random]
              } else {
                product.name = product.name[0]
                product.imageUrl = product.imageUrl[0]
              }
              delete playList.id

              finalList.push(finalItem)
            })

            context.commit('setUserTopGenres', finalList)
            isUserTopGenresLoaded = true
          }
        }
      }
      if (!isUserTopGenresLoaded) {
        const otherGenre = { ...context.state.otherStaticGenreGroup }

        const { product,playList } = otherGenre
        if (product.name.length > 1) {
          const random = Common.randomInteger(0, product.name.length - 1)
          product.name = product.name[random]
          product.imageUrl = product.imageUrl[random]
          playList.id = playList.id[random]
        } else {
          product.name = product.name[0]
          product.imageUrl = product.imageUrl[0]
          playList.id = playList.id[0]
        }
        context.commit('setUserTopGenres', [otherGenre])
      }
    },
    async loadOfflineTrackGenres(context) {
      const artists = await fetch(
        `https://api.spotify.com/v1/artists?ids=${encodeURIComponent(
          context.state.offlineTrack.artistIds.join(',')
        )}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${context.state.accessToken}`
          }
        }
      )
      const artistsJson = await artists.json()
      if (artistsJson?.artists?.length > 0) {
        let genres = new Set(artistsJson?.artists?.flatMap((item) => item.genres))
        console.log('Offline track all genres')
        console.log(genres)

        let matchedGenre
        genres.forEach((genre) => {
          if (!matchedGenre) {
            context.state.staticGenreGroups.forEach((staticGenreItem) => {
              if (!matchedGenre) {
                staticGenreItem.keywords.forEach((keyword) => {
                  if (!matchedGenre) {
                    if (genre === keyword) {
                      matchedGenre = { ...staticGenreItem }
                    }
                  }
                })
              }
            })
          }
        })
        if (!matchedGenre) matchedGenre = { ...context.state.otherStaticGenreGroup}
        if(genres.size>0)
          matchedGenre.title=[...genres][0]
        const { product,playList } = matchedGenre
        if (product.name.length > 1) {
          const random = Common.randomInteger(0, product.name.length - 1)
          product.name = product.name[random]
          product.imageUrl = product.imageUrl[random]
          playList.id=playList.id[random]
        } else {
          product.name = product.name[0]
          product.imageUrl = product.imageUrl[0]
          playList.id=playList.id[0]
        }
        context.commit('setUserTopGenres', [matchedGenre])
      }
    },
    async generatePlaylist(context) {
      const topTracks = await fetch('https://api.spotify.com/v1/me/top/tracks?limit=50&time_range=long_term', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${context.state.accessToken}`
        }
      })
      const topTracksJson = await topTracks.json()
      if (topTracksJson?.items?.length > 0) {
        const artistIds = new Set(
          topTracksJson.items.flatMap((track) => track.artists?.map((artist) => artist.id))
        )
        if (artistIds?.size > 0) {
          const artists = await fetch(
            `https://api.spotify.com/v1/artists?ids=${encodeURIComponent(
              [...artistIds].slice(0, 50).join(',')
            )}`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${context.state.accessToken}`
              }
            }
          )
          const artistsJson = await artists.json()
          if (artistsJson?.artists?.length > 0) {
            const filteredArtists = artistsJson.artists.filter((artist) => {
              if (artist.genres.includes(context.state.userTopGenres[0].title)) return true
              return false
            })
            if (filteredArtists?.length > 0) {
              const topTracks = []
              if (filteredArtists.length > 1) {
                topTracks.push(topTracksJson.items.find((item) =>
                  item.artists.some((innerItem) => innerItem.id === filteredArtists[0].id)))
                topTracks.push(topTracksJson.items.find((item) =>
                  item.artists.some((innerItem) => innerItem.id === filteredArtists[1].id)))
              } else {
                topTracks.push(topTracksJson.items.find((item) =>
                  item.artists.some((innerItem) => innerItem.id === filteredArtists[0].id)))}
              const recommendedTracks = await fetch(
                `https://api.spotify.com/v1/recommendations?limit=20&market=${
                  process.env.VUE_APP_MARKET
                }&seed_artists=${encodeURIComponent(
                  filteredArtists
                    .map((item) => item.id)
                    .slice(0, 2)
                    .join(',')
                )}&seed_genres=${encodeURIComponent(
                  context.state.userTopGenres[0].title
                )}&seed_tracks=${encodeURIComponent(topTracks.map((item) => item.id).slice(0, 2).join(','))}`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${context.state.accessToken}`
                  }
                }
              )
              const recommendedTracksJson = await recommendedTracks.json()
              if (recommendedTracksJson?.tracks?.length > 0) {
                const playlistCreateResponse = await fetch(
                  `https://api.spotify.com/v1/users/${context.state.userId}/playlists`,
                  {
                    headers: {
                      Authorization: `Bearer ${context.state.accessToken}`
                    },
                    method: 'POST',
                    body: JSON.stringify({
                      name: context.state.userTopGenres[0].playList.title + context.state.userTopGenres[0].product.name,
                      description: context.state.userTopGenres[0].playList.description
                    })
                  }
                )
                const playlistCreateResponseJson = await playlistCreateResponse.json()

                Common.getBase64FromUrl(context.state.userTopGenres[0].playList.imageUrl).then(
                  async (base64Data) => {
                    await fetch(
                      `https://api.spotify.com/v1/playlists/${playlistCreateResponseJson.id}/images`,
                      {
                        headers: {
                          Authorization: `Bearer ${context.state.accessToken}`,
                          'Content-Type': 'image/jpeg'
                        },
                        method: 'PUT',
                        body: base64Data
                      }
                    )
                    await fetch(
                      `https://api.spotify.com/v1/playlists/${
                        playlistCreateResponseJson.id
                      }/tracks?uris=${encodeURIComponent(
                        recommendedTracksJson.tracks
                          .map((item) => `spotify:track:${item.id}`)
                          .join(',')
                      )}`,
                      {
                        method: 'POST',
                        headers: {
                          Authorization: `Bearer ${context.state.accessToken}`
                        }
                      }
                    )
                    context.commit('setCreatedPlayListId', playlistCreateResponseJson.id)
                  }
                )
              }
            }
          }
        }
      } else {
        const staticPlaylistResponse = await fetch(
          `https://api.spotify.com/v1/playlists/${context.state.userTopGenres[0].playList.id}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.accessToken}`
            }
          }
        )
        const staticPlaylistResponseJson = await staticPlaylistResponse.json()
        if (staticPlaylistResponseJson?.tracks?.items.length > 0) {
          const trackIds = staticPlaylistResponseJson.tracks.items.map((item) => item.track.id)
          const playlistCreateResponse = await fetch(
            `https://api.spotify.com/v1/users/${context.state.userId}/playlists`,
            {
              headers: {
                Authorization: `Bearer ${context.state.accessToken}`
              },
              method: 'POST',
              body: JSON.stringify({
                name: context.state.userTopGenres[0].playList.title + context.state.userTopGenres[0].product.name,
                description: context.state.userTopGenres[0].playList.description
              })
            }
          )
          const playlistCreateResponseJson = await playlistCreateResponse.json()
          Common.getBase64FromUrl(context.state.userTopGenres[0].playList.imageUrl)
            .then(
              async (base64Data) => {
                await fetch(
                  `https://api.spotify.com/v1/playlists/${playlistCreateResponseJson.id}/images`,
                  {
                    headers: {
                      Authorization: `Bearer ${context.state.accessToken}`,
                      'Content-Type': 'image/jpeg'
                    },
                    method: 'PUT',
                    body: base64Data
                  }
                )
                await fetch(
                  `https://api.spotify.com/v1/playlists/${
                    playlistCreateResponseJson.id
                  }/tracks?uris=${encodeURIComponent(
                    trackIds.map((item) => `spotify:track:${item}`)
                      .join(',')
                  )}`,
                  {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${context.state.accessToken}`
                    }
                  }
                )

                context.commit('setCreatedPlayListId', playlistCreateResponseJson.id)
              }
            )
        }
      }
    }
    // async generateStaticPlaylist(context) {
    //   const playlistTitle='Burger Mystère - Double Cheese Bacon XXL'
    //   const artistId='1sd5vbMAYXlLoJNTQfY0HV'
    //   const genres=['pop band','k-pop','00’s']
    //   const trackId='3MjUtNVVq3C8Fn0MP3zhXa'
    //
    //   const burgerImage='Playlist-Cover-Visual.jpg'
    //   const description='Seul ton style musical peut déceler ce mystère.'
    //
    //   const recommendedTracks = await fetch(
    //     `https://api.spotify.com/v1/recommendations?limit=20&market=${
    //       process.env.VUE_APP_MARKET
    //     }&seed_artists=${encodeURIComponent(
    //       artistId
    //     )}&seed_genres=${encodeURIComponent(
    //       genres.join(',')
    //     )}&seed_tracks=${encodeURIComponent(trackId)}`,
    //     {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${context.state.accessToken}`
    //       }
    //     }
    //   )
    //   const recommendedTracksJson = await recommendedTracks.json()
    //   if (recommendedTracksJson?.tracks?.length > 0) {
    //     const playlistCreateResponse = await fetch(
    //       `https://api.spotify.com/v1/users/${context.state.userId}/playlists`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${context.state.accessToken}`
    //         },
    //         method: 'POST',
    //         body: JSON.stringify({
    //           name: playlistTitle,
    //           description: description
    //         })
    //       }
    //     )
    //     const playlistCreateResponseJson = await playlistCreateResponse.json()
    //
    //     Common.getBase64FromUrl(burgerImage).then(
    //       async (base64Data) => {
    //         await fetch(
    //           `https://api.spotify.com/v1/playlists/${playlistCreateResponseJson.id}/images`,
    //           {
    //             headers: {
    //               Authorization: `Bearer ${context.state.accessToken}`,
    //               'Content-Type': 'image/jpeg'
    //             },
    //             method: 'PUT',
    //             body: base64Data
    //           }
    //         )
    //         await fetch(
    //           `https://api.spotify.com/v1/playlists/${
    //             playlistCreateResponseJson.id
    //           }/tracks?uris=${encodeURIComponent(
    //             recommendedTracksJson.tracks
    //               .map((item) => `spotify:track:${item.id}`)
    //               .join(',')
    //           )}`,
    //           {
    //             method: 'POST',
    //             headers: {
    //               Authorization: `Bearer ${context.state.accessToken}`
    //             }
    //           }
    //         )
    //         console.log(playlistCreateResponseJson.id)
    //       }
    //     )
    //   }
    //
    //   const playlistCreateResponse = await fetch(
    //     `https://api.spotify.com/v1/users/${context.state.userId}/playlists`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${context.state.accessToken}`
    //       },
    //       method: 'POST',
    //       body: JSON.stringify({
    //         name: '',
    //         description: ''
    //       })
    //     }
    //   )
    //   const playlistCreateResponseJson = await playlistCreateResponse.json()
    //
    //   Common.getBase64FromUrl('').then(
    //     async (base64Data) => {
    //       await fetch(
    //         `https://api.spotify.com/v1/playlists/${playlistCreateResponseJson.id}/images`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${context.state.accessToken}`,
    //             'Content-Type': 'image/jpeg'
    //           },
    //           method: 'PUT',
    //           body: base64Data
    //         }
    //       )
    //       await fetch(
    //         `https://api.spotify.com/v1/playlists/${
    //           playlistCreateResponseJson.id
    //         }/tracks?uris=${encodeURIComponent(
    //           recommendedTracksJson.tracks
    //             .map((item) => `spotify:track:${item.id}`)
    //             .join(',')
    //         )}`,
    //         {
    //           method: 'POST',
    //           headers: {
    //             Authorization: `Bearer ${context.state.accessToken}`
    //           }
    //         }
    //       )
    //       context.commit('setCreatedPlayListId', playlistCreateResponseJson.id)
    //     }
    //   )
    // }
    // async generateStaticPlaylist(context) {
    //   const playlistTitle='Burger Mystère - Double Cheese Bacon XXL'
    //   const artistId='1sd5vbMAYXlLoJNTQfY0HV'
    //   const genres=['pop band','k-pop','00’s']
    //   const trackId='3MjUtNVVq3C8Fn0MP3zhXa'
    //
    //   const burgerImage='Playlist-Cover-Visual.jpg'
    //   const description='Seul ton style musical peut déceler ce mystère.'
    //
    //   const recommendedTracks = await fetch(
    //     `https://api.spotify.com/v1/recommendations?limit=20&market=${
    //       process.env.VUE_APP_MARKET
    //     }&seed_artists=${encodeURIComponent(
    //       artistId
    //     )}&seed_genres=${encodeURIComponent(
    //       genres.join(',')
    //     )}&seed_tracks=${encodeURIComponent(trackId)}`,
    //     {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${context.state.accessToken}`
    //       }
    //     }
    //   )
    //   const recommendedTracksJson = await recommendedTracks.json()
    //   if (recommendedTracksJson?.tracks?.length > 0) {
    //     const playlistCreateResponse = await fetch(
    //       `https://api.spotify.com/v1/users/${context.state.userId}/playlists`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${context.state.accessToken}`
    //         },
    //         method: 'POST',
    //         body: JSON.stringify({
    //           name: playlistTitle,
    //           description: description
    //         })
    //       }
    //     )
    //     const playlistCreateResponseJson = await playlistCreateResponse.json()
    //
    //     Common.getBase64FromUrl(burgerImage).then(
    //       async (base64Data) => {
    //         await fetch(
    //           `https://api.spotify.com/v1/playlists/${playlistCreateResponseJson.id}/images`,
    //           {
    //             headers: {
    //               Authorization: `Bearer ${context.state.accessToken}`,
    //               'Content-Type': 'image/jpeg'
    //             },
    //             method: 'PUT',
    //             body: base64Data
    //           }
    //         )
    //         await fetch(
    //           `https://api.spotify.com/v1/playlists/${
    //             playlistCreateResponseJson.id
    //           }/tracks?uris=${encodeURIComponent(
    //             recommendedTracksJson.tracks
    //               .map((item) => `spotify:track:${item.id}`)
    //               .join(',')
    //           )}`,
    //           {
    //             method: 'POST',
    //             headers: {
    //               Authorization: `Bearer ${context.state.accessToken}`
    //             }
    //           }
    //         )
    //         console.log(playlistCreateResponseJson.id)
    //       }
    //     )
    //   }
    //
    //   const playlistCreateResponse = await fetch(
    //     `https://api.spotify.com/v1/users/${context.state.userId}/playlists`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${context.state.accessToken}`
    //       },
    //       method: 'POST',
    //       body: JSON.stringify({
    //         name: '',
    //         description: ''
    //       })
    //     }
    //   )
    //   const playlistCreateResponseJson = await playlistCreateResponse.json()
    //
    //   Common.getBase64FromUrl('').then(
    //     async (base64Data) => {
    //       await fetch(
    //         `https://api.spotify.com/v1/playlists/${playlistCreateResponseJson.id}/images`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${context.state.accessToken}`,
    //             'Content-Type': 'image/jpeg'
    //           },
    //           method: 'PUT',
    //           body: base64Data
    //         }
    //       )
    //       await fetch(
    //         `https://api.spotify.com/v1/playlists/${
    //           playlistCreateResponseJson.id
    //         }/tracks?uris=${encodeURIComponent(
    //           recommendedTracksJson.tracks
    //             .map((item) => `spotify:track:${item.id}`)
    //             .join(',')
    //         )}`,
    //         {
    //           method: 'POST',
    //           headers: {
    //             Authorization: `Bearer ${context.state.accessToken}`
    //           }
    //         }
    //       )
    //       context.commit('setCreatedPlayListId', playlistCreateResponseJson.id)
    //     }
    //   )
    // }
  }
})

export default store

