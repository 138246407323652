export default {
  async getBase64FromUrl(url) {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        resolve(base64data.split(',')[1])
      }
    })
  },
  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
}
