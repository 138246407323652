import Vue from 'vue'
import VueMobileDetection from 'vue-mobile-detection'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './assets/layout.css'

Vue.config.productionTip = false
Vue.use(VueMobileDetection)

router.afterEach((to, from) => {
  gtag('set', 'page_path', to.path)
  gtag('event', 'page_view')
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
