<!-- eslint-disable max-len -->
<template>
  <div
    id="footer"
    class="px-2rem px-2 d-flex flex-column flex-lg-row justify-content-between align-items-center mb-3 mt-4 bottom-0 "
    :class="{'is-absolute':isAbsolute}"
  >
    <div
      id="left"
      class="order-1 order-lg-0 mt-3 mt-lg-0"
    >
      <div id="left-top">
        <span>TM &#38; Copyright 2021 Burger King Corporation.<br class="d-inline d-lg-none"> All Rights Reserved
          .</span><br
            class="d-md-none"
          >
        <a
          href="https://www.burgerking.fr/page/mentions-legales"
          target="_blank"
          rel="noreferrer"
          class="ms-3 mt-2 mt-lg-0 d-inline-block d-lg-inline"
          @click="onOpenLink"
        >Privacy Policy</a>
        <span class="mx-2">|</span>
        <a
          href="https://www.burgerking.fr/page/cgv"
          target="_blank"
          rel="noreferrer"
          @click="onOpenLink"
          class="mt-2 mt-lg-0 d-inline-block d-lg-inline"
        >Terms of Use</a>
        <span class="mx-2">|</span>
        <a
          href="https://www.spotify.com/fr/account/apps/"
          target="_blank"
          rel="noreferrer"
          @click="onOpenLink"
          class="mt-2 mt-lg-0 d-inline-block d-lg-inline"
        >Revoke Access</a>
      </div>
      <div class="mt-2 mt-lg-1">
        <span>Pour votre santé, mangez au moins cinq fruits et légumes par jour.</span>
        <a
          href="http://www.mangerbouger.fr"
          target="_blank"
          class="ms-2"
          @click="onOpenLink"
        >www.mangerbouger.fr</a>
      </div>
    </div>
    <img
      src="../assets/images/listen-spotify.svg"
      alt="Listen on Spotify"
      id="listen-spotify"
      class="mt-0 order-0 order-lg-1"
    >
  </div>
</template>
<script>
export default {
  props: { isAbsolute: Boolean },
  methods: {
    onOpenLink(e) {
      gtag('event', 'open_external_link', e.target.href)
    }
  }
}
</script>
<style scoped>
#footer:not(.is-absolute) {
  z-index: 5;
}
#left {
  font-size: 0.8rem;
  font-family: RobotoCondensedRegular;
}
#left a {
  color: black;
}

@media (max-width: 991px){
  #left{
    text-align: center;
  }
  #listen-spotify {
    max-height: 1.7rem;
  }
}
@media (min-width: 992px){
  #footer.is-absolute {
    width: calc(100% - var(--bs-gutter-x));
  }
#footer.is-absolute{
    position: absolute;
  }
  #listen-spotify {
    max-height: 2.1rem;
  }
}

</style>
