<!--eslint-disable max-len-->
<template>
  <div class="min-vh-100 d-flex flex-column justify-content-between">
    <background-image />
    <div class="container-fluid flex-grow-1 d-flex pt-4">
      <div
        class="d-flex flex-column flex-grow-1 flex-lg-row px-2rem"
        id="content"
      >
        <div class="text-center pt-4 ps-0 flex-grow-1 me-lg-3">
          <div class="text-center mb-4">
            <img
              src="../assets/images/logo.svg"
              id="logo"
            >
          </div>
          <h1 class="mb-4 FlameBold">
            Ton amour pour la <br>
            <span
              id="genre-name"
              class="text-uppercase"
            >{{ genre }}</span> s’accorde<br> parfaitement avec le<br>
            <span
              id="burger-name"
              class="text-uppercase"
            >{{ burger }}</span>
          </h1>
          <a
            :href="`https://open.spotify.com/playlist/${playListId}`"
            target="_blank"
            @click="onOpenLink"
          >
            <button
              id="btn-playlist"
              class="btn BlockBertholdCondensedRegular mb-4 btn-global"
            >
              DÉCOUVRE TA PLAYLIST MYSTÈRE
            </button>
          </a>
        </div>
        <div class="d-none d-lg-flex align-items-center pe-0">
          <img
            src="../assets/images/arrow.svg"
            class="w-100"
            id="arrow"
          >
        </div>
        <div class="pt-4 text-center flex-grow-1 ms-lg-3">
          <div class="mb-4">
            <img
              src="../assets/images/sub-logo.png"
              alt="Burger Mystere"
              id="sub-logo"
            >
          </div>
          <div class="text-center mb-3">
            <img
              :src="require(`../assets/images/products/${productImage}`)"
              id="surprise-box"
            >
          </div>
          <p
            class="BlockBertholdCondensedRegular mb-4"
            id="description"
          >
            <span id="sub-description">Burger Mystère, <br class="d-inline d-xl-none">du 6 septembre au 2 octobre
              2022<br> dans tous les
              restaurants
              Burger King.</span><br>
            Télécharge l’application maintenant !
          </p>
          <div
            class="d-flex justify-content-center align-items-center"
            id="store-container"
          >
            <a
              href="https://itunes.apple.com/fr/app/burger-king-france/id924879230?mt=8"
              target="_blank"
              @click="onOpenLink"
            >
              <img src="../assets/images/apple-store.png">
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=air.com.unit9.bkFrApp"
              target="_blank"
              @click="onOpenLink"
            >
              <img src="../assets/images/google-store.png">
            </a>
          </div>
        </div>
      </div>
    </div>
    <page-footer z-index="4" />
  </div>
</template>
<script>
import BackgroundImage from '../components/BackgroundImage.vue'
import PageFooter from '../components/PageFooter.vue'

export default {
  name: 'ResultPage',
  components: {
    BackgroundImage,
    PageFooter
  },
  mounted() {
    gtag('event', 'result', {
      genre: this.genre,
      burger: this.burger
    })
  },
  computed: {
    playListId() {
      return this.$store.getters.getCreatedPlayListId
    },
    genre() {
      return this.$store.state.userTopGenres[0].title
    },
    burger() {
      return this.$store.state.userTopGenres[0].product.name
    },
    productImage() {
      return this.$store.state.userTopGenres[0].product.imageUrl
    }
  },
  methods: {
    onOpenLink(e) {
      gtag('event', 'open_external_link', e.target.href)
    }
  }
}
</script>

<style scoped>
#content{
  z-index: 3;
}
#logo {
  max-width: 150px;
}
#burger-name{
  color:#bc1e31
}
#genre-name{
  color:#ee7604
}
#arrow{
  max-width: 200px;
}
#sub-description{
  color:#bc1e31
}
#description{
  font-size: 1.2rem;
  line-height: 1.4rem;
}
#store-container{
  column-gap: 10px;
}
#store-container img{
  column-gap: 10px;
  width: 125px;
}

@media (max-width: 991px) {
  #surprise-box{
    width: 90%;
  }
  #sub-logo {
    width: 90%;
  }
}
@media (min-width: 992px) {
  #sub-logo,#surprise-box {
    max-width: 530px;
    width: 100%;
  }
}
</style>
