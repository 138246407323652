<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch('loadStaticInfos')
  },
  mounted() {
    window.addEventListener('resize', this.calculateFooter)
    setTimeout(() => {
      this.calculateFooter()
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateFooter)
  },
  methods: {
    calculateFooter() {
      const footer = document.getElementById('footer')
      const styles = window.getComputedStyle(footer)
      const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom)
      const height = Math.ceil(margin + footer.offsetHeight)
      const marginFooterElements = document.querySelectorAll('.footer-margin')
      if (marginFooterElements?.length > 0) {
        marginFooterElements.forEach((item) => {
          item.style.marginBottom = `${height}px`
        })
      }
    }
  }
}
</script>
